import React,{useState, useEffect} from 'react'
import UserNav from './UserNav'
import interest from "../assests/interest.jpg"
import Option from './Option';
import axios from "axios";


const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };
export default function Loans() {
    const [loan_name, setLoan_Name] = useState([]);

  
    useEffect(() => {
      axios
          .get("https://akms.org.in:2045/endpoint/get-loann") // Use your live domain
          .then((result) => setLoan_Name(result.data))
          .catch((err) => console.log(err));
  }, []);
  return (

      <div>
      <UserNav />
      <Option />
      <div>
    
        <div style={imgAboutBadivStyle}>
          <img src={interest} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>

        <div className="container">
       
          <div className="row justify-content-center">
          <h3 style={{color:"purple",  textAlign: "center"}}>Loans</h3>
            <div className="col-md-5">
              <div >
                
                <div className="card-body">
               
                 
                  <table className="table table-bordered table-striped">
                  <thead className='text-center'>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "left" }}
                      >
                        Loans Name
                      </th>
                     
                    
                    </tr>
                  </thead>
                  <tbody>
                    {loan_name.map((item) => (
                      <tr key={item._id}>
                       <td>{item.loan_name}</td>
             
                      
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
  )
}
