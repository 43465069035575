import React from 'react'
import UserNav from './UserNav'
import Awards from "../assests/Awards1.jpg"
import Awards1 from "../assests/Award.jpg"
import Option from './Option';

const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };

    
const AwardsStyle = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "30px",
    marginLeft: "206px",
    color: "#8e0c56",
  };
  
  const paraStyle = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "202px",
    paddingRight: "380px", // Adjusted padding right
    paddingTop: "20px",
    lineHeight: "1.5",
  };
  
  const imgPresentStyle = {
    width: "850px", // Explicitly set width
    height: "500px", // Maintain aspect ratio
    marginTop: "10px",
    paddingTop: "10px",
    marginLeft: "200px",
  };
export default function Award() {
  
  return (
    <div>
        <UserNav />
      <Option />
      <div>
    
        <div style={imgAboutBadivStyle}>
          <img src={Awards} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
        <div>
        <h1 style={AwardsStyle}>Awards</h1>
      </div>
      <div>
        <p style={paraStyle}>
          Our Souharda is recognized by Karnataka State Souharda Federal
          Cooperative Limited as best Souharda among Souharda Cooperatives of
          Mysore Division. We Thank to all Members for continuous support for
          Winning this Award.
        </p>
      </div>
      <div>
        <img src={Awards1} alt="About Us" style={imgPresentStyle} />
      </div>
      <div>
        <p style={paraStyle}>
          Our President Mr. S R Sathischandra, Director Mr. Sunil Borkar and
          General Manager Mr. Vasanth Received this Award in 60th All India
          Co-operative saptah -2013 held at T. Chennayya hall Kolar District.
          Co-operative minister Mr. H. Mahadeva Prasad, Mr. G T Devegowda
          President of Karnataka State co-operative Mahamandala, Mr K. R. Ramesh
          Kumar Ex. President of Karnataka Legislative Assembly, Mr. Gurunath
          Jyanthikar President of Karnataka State Souharda Federal Co-operative
          were present in this Function.
        </p>
      </div>
        </div>
    </div>
  )
}
