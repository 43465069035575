import React,{useState, useEffect} from 'react'
import UserNav from './UserNav'
import Financial1 from "../assests/Financial.jpg"
import Option from './Option';
import axios from "axios";

const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };
export default function Financial() {

    const [financial, setFinancial] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:2045/endpoint/get-financiall")
        .then((result) => setFinancial(result.data))
        .catch((err) => console.log(err));
    }, []);
  
  return (
    <div>
       <UserNav />
      <Option />
      <div>
    
        <div style={imgAboutBadivStyle}>
          <img src={Financial1} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
        <div className="container">
      <div className="row justify-content-center">
      <h3 style={{color:"purple",  textAlign: "center"}}>Financial Details</h3>
        <div className="col-md-9">
     
          <div >
            <div className="card-body">
             
            <h4 style={{ backgroundColor:"purple", textAlign: "center", color:"#fff" }}> THE BIRD EYE VIEW OF PROGRESS OF THE SAHAKARI </h4>
                 
             
              <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                  Year
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Members
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Share Capital
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Deposits
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Loans
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Reserves
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Net Profit
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Dividend %
                  </th>
                
                </tr>
              </thead>
              <tbody className="text-center">
                {financial.map((item) => (
                  <tr key={item._id}>
                   <td>{item.year}</td>
            <td>{item.members}</td>
            <td>{item.share_capital}</td>
            <td>{item.deposits}</td>
            <td>{item.loans}</td>
            <td>{item.reserves}</td>
            <td>{item.net_profit}</td>
            <td>{item.dividend}</td>
                  
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
    </div>
  )
}
