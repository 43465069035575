import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function UpdateFinancial() {
    const {id} = useParams()

    const [year, setYear] = useState()
    const [members, setMembers] = useState()
    const [share_capital, setShare_Capital] = useState()
    const [deposits, setDeposits] = useState()
    const [loans, setLoans] = useState()
    const [reserves, setReserves] = useState()
    const [net_profit, setNet_Profit] = useState()
    const [dividend, setDividend] = useState()


    useEffect(() =>{
        axios.get('http://localhost:2045/endpoint/financial/'+id)
        .then(result => {console.log(result)
            setMembers(result.data.members)
            setYear(result.data.year)
            setShare_Capital(result.data.share_capital)
            setDeposits(result.data.deposits)
            setLoans(result.data.loans)
            setReserves(result.data.reserves)
            setNet_Profit(result.data.net_profit)
            setDividend(result.data.dividend)
          
       
        
        })
        .catch(err => console.log(err))
      }, [id])

    const navigate = useNavigate()
    const Update =(e) => {
        e.preventDefault();
            axios.put('http://localhost:2045/endpoint/update-financial/'+id, {year, members, share_capital, deposits, loans, reserves, net_profit, dividend})
            .then(result => {console.log(result)
                navigate('/financial-list')
    
        })
            .catch(err => console.log(err))
    
        }
  return (
    <div>
      <Navbar ></Navbar>
       <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 >Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update}> 
         
  
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Year</b></label>
                        <input type="text" formControlName="category_desc" value={year} class="form-control" onChange={(e)=> setYear(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Members</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={members} onChange={(e)=> setMembers(e.target.value)} />

                    </div>
                    </div>
                    </div>
                  
                

                 
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b></b></label>
                        <input type="text" formControlName="category_desc" value={share_capital} class="form-control" onChange={(e)=> setShare_Capital(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Deposits</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={deposits} onChange={(e)=> setDeposits(e.target.value)} />

                    </div>
                    </div>
                    </div>
            
                   
                    
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Loans</b></label>
                        <input type="text" formControlName="category_desc" value={loans} class="form-control" onChange={(e)=> setLoans(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Reserves</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={reserves} onChange={(e)=> setReserves(e.target.value)} />

                    </div>
                    </div>
                    </div>
            

 
                    <div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Net Profit</b></label>
                        <input type="text" formControlName="category_desc" value={net_profit} class="form-control" onChange={(e)=> setNet_Profit(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Dividend</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={dividend} onChange={(e)=> setDividend(e.target.value)} />

                    </div>
                    </div>
                    </div>
            
                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/financial-list" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>


    </div>
  )
}
