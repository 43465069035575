import React from 'react'
import UserNav from './UserNav'
import sathish from "../Management/Sathischandra.png"
import vasanth from "../Management/Vasanth.jpg"
import deviprasad from "../Management/Deviprasad.png"
import chandra from "../Management/Chandrashekhar.png"
import prabhu1 from "../Management/Prabhu.png"


const aboutStyle = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "20px",
    marginLeft: "210px",
    color: "#52002e",
  };
  
  const aboutStyle2 = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "30px",
    marginLeft: "210px",
    color: "#52002e",
  };
  
  const paraStyle = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "205px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "25px",
    lineHeight: "1.6", // Adjust line height as needed
  };
  
  const paraStyle2 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "205px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.8", // Adjust line height as needed
  };
  
  const paraStyleStrong = {
    fontFamily: "sans-serif",
  };
  
  const paraStyleind1 = {
    fontSize: "25px",
    fontFamily: "sans-serif",
    paddingLeft: "200px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.8", // Adjust line height as needed
  };
  
  const paraStyletex1 = {
    fontSize: "25px",
    fontFamily: "sans-serif",
    paddingLeft: "235px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.5", // Adjust line height as needed
  };
  
  const lowerAlphaListStyle = {
    listStyleType: "lower-alpha",
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "25px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.5", // Adjust line height as needed
  };
  
  const listfont = {
    fontFamily: "sans-serif",
  };
  
  const hr4style = {
    fontSize: "25px",
    fontFamily: "sans-serif",
    paddingLeft: "200px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.8", // Adjust line height as needed
  };
  
  const lowerAlphaListStyle2 = {
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "270px",
    margin: "10px", // Added margin for spacing around the paragraph
    paddingRight: "150px",
    paddingTop: "10px",
    lineHeight: "1.6", // Adjust line height as needed
  };
  
  const aboutStyle3 = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "-10px",
    marginLeft: "210px",
    color: "#52002e",
  };
  
  const paraStyle6 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "150px",
    paddingRight: "190px",
    paddingTop: "1px",
    marginTop: "25px",
    marginLeft: "30px",
  };
  
  const paraStyle7 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "600px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-145px",
  };
  
  const paraStyle8 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "970px",
    paddingRight: "100px",
    paddingTop: "1px",
    marginTop: "-100px",
    marginLeft: "31px",
  };
  
  const paraStyle9 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "150px",
    paddingRight: "190px",
    paddingTop: "1px",
    marginTop: "25px",
    marginLeft: "30px",
  };
  
  const paraStyle10 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "600px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-145px",
  };
  
  const imgPrakash = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "50px",
    marginLeft: "155px",
  };
  
  const imgHemanth = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "70px",
  };
  
  const imgRamesh = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "70px",
  };
  
export default function Subsidiary() {
  return (
    <div>
        <UserNav />
        <div>
        <h1 style={aboutStyle}>About Trust</h1>
        <p style={paraStyle}>
          Shri Saraswathi Charitable Trust is the subsidiary institution of Shri
          Saraswathi Credit Souharda Sahakari Ltd which is founded in accordance
          with section15 of Karnataka Souharda Sahakari Act 1997 . The prime
          motto of the Trust is social commitment. ie. Concern for community
          which is one of the cooperative principles.
          <br />
          <br />
          Shri Saraswathi Credit Souharda Sahakari Ltd being a socio economic
          entity since inception it is performing various social activities in
          various platforms. To make these activates in a systematic & more
          meaningful way the trust was founded and officially inaugurated on
          23.05.2015 . The trust registered under Indian Trust Act 1882 and
          Registration Number 500/2014-15.
          <br />
          <br />
          As a cooperative Shri Saraswathi credit Souharda sahakari Ltd is
          serving through various social & economic activities for empowerment
          of members through mutual co-operation to implement and execute
          accepted co-operative policy of concern for community. Sahakari is
          always extending its commitment towards social awareness programmes
          and helping needy members of the society.
          <br />
          <br />
          To empower poor and marginalized people to take advantage of
          burgeoning global opportunity Shri Saraswathi Charitable Trust aims
          adopt the concept of social inclusion .It is the process of improving
          the terms for individuals to participate in community and society . To
          encourage the contribution of all persons to social and cultural life.
        </p>
      </div>

      <div>
        <h1 style={aboutStyle2}>Contact Us:</h1>
        <p style={paraStyle2}>
          The registered office of the Trust is situated at:-
          <br />
          “Sachidananda Seva Sadana,
          <br />
          Vinayaka Nagara, Darbe,
          <br />
          Puttur -574 202,
          <br />
          Dakshina Kannada District.
          <br />
          <br />
          <strong style={paraStyleStrong}>Email:</strong>
          trust@saraswathisahakari.com
          <br />
          <strong style={paraStyleStrong}> Web: </strong>{" "}
          www.saraswathisahakari.com
          <br />
          <strong style={paraStyleStrong}>Ph No:</strong> 08251- 237223
          <br />
          <strong style={paraStyleStrong}>Mob:</strong> 7022284004
        </p>
        <div>
          <h4 style={paraStyleind1}>
            <strong style={paraStyleStrong}>
              Shri Saraswathi Charitable Trust has set forth the below
              objectives.
            </strong>
          </h4>
        </div>
        <div>
          <ol style={paraStyletex1}>
            <li>
              <strong style={paraStyleStrong}>Relief of the poor</strong>
              <ol style={lowerAlphaListStyle}>
                <li style={listfont}>
                  Identifying and honoring any eminent personalities in the
                  field of agriculture, horticulture, cooperation, economics,
                  sports, arts, commerce, science, education and literature
                  etc.,
                </li>
                <br />
                <li style={listfont}>
                  Organizing, arranging and maintaining rehabilitation centre
                  for handicapped, disabled, aged, orphan, victims of natural
                  calamities, victims of epidemic diseases or social evils
                  either its own or in association with other organization /
                  institution.
                </li>
                <br />
                <li style={listfont}>
                  To arrange or assisting to arrange annadasoha, i.e., providing
                  food for students and needy people.
                </li>
                <br />
              </ol>
            </li>
            <li>
              <strong style={paraStyleStrong}>Education</strong>
              <ol style={lowerAlphaListStyle}>
                <li style={listfont}>
                  Establishing, managing and maintaining or assist in
                  establishing, managing and maintaining educational
                  institutions imparting academic education including
                  pre-primary, primary, secondary, higher secondary, high
                  school, pre-university, graduation, post graduation and
                  research and development in various fields including Arts,
                  Commerce, Science, Law, Information Technology, Language,
                  Astrology, Veda, Spirituality, Ayurveda, Yoga, Sidda,
                  Homeopathy, Naturopathy, Alopathy.
                </li>
                <br />
                <li style={listfont}>
                  Establishing, managing and maintaining or assist in
                  establishing, managing and maintaining educational
                  institutions imparting professional and technical education in
                  the field of Engineering, Polytechnics, Industrial and
                  Vocational Training, Skill Development, General Management,
                  Communication Skills, Personality Development, and/or Motor
                  Vehicle Driving, swimming, tailoring, beautician course etc.
                </li>
                <br />
                <li style={listfont}>
                  To organize, arrange and conduct interactive sessions and
                  training programmes for competitive exams such as IAS, IRS,
                  IFS, CET, KAS, and KMAS etc.
                </li>
                <br />
              </ol>
            </li>
            <li>
              <strong style={paraStyleStrong}>Medical Relief</strong>
              <ol style={lowerAlphaListStyle}>
                <li style={listfont}>
                  Identifying Blood Donors, Maintaining Blood Donors Register,
                  Conduct Blood Donation Camp(s) and establishment of Blood
                  Bank,
                </li>
                <br />
                <li style={listfont}>
                  Arranging Ambulance Service, and providing Health Education to
                  Public
                </li>
                <br />
                <li style={listfont}>
                  Creating awareness among the public towards Blood Donation,
                  Eye Donation, Body Donation etc.
                </li>
                <br />
              </ol>
            </li>
            <li>
              <strong style={paraStyleStrong}>
                Preservation of Environment
              </strong>
              <ol style={lowerAlphaListStyle}>
                <li style={listfont}>
                  To promote and conduct social awareness programmes for the
                  general public towards preserving environment, food, energy,
                  water, hygienity etc.
                </li>
                <br />
                <li style={listfont}>
                  To provide information regarding Proper utilization and
                  preservation of natural resources.
                </li>
                <br />
                <li style={listfont}>
                  Establishing, managing and maintaining or assist in
                  establishing, managing and maintaining Solid Waste Management,
                  rooftop farming.
                </li>
              </ol>
            </li>
          </ol>
          <p style={hr4style}>
            <strong style={listfont}>
              Other Object of General Public Utility
            </strong>
          </p>
          <ol style={lowerAlphaListStyle2}>
            <li style={listfont}>
              To promote, guide, manage, name, recognize and affiliate “Self
              Help Groups” or “joint liability group” of Individuals engaged in
              the fields of Agriculture, Horticulture, Sericulture, Animal
              Husbandry, Fishing, Bee Keeping, Handicrafts, and Village
              Industries as an owner or worker for their mutual benefits.
            </li>
            <br />
            <li style={listfont}>
              To organize, promote, guide, manage, recognize and affiliate
              various units of “Self Help Groups” or “Joint Liability Groups” at
              Village, Hobli, Taluk, District or State level and form a
              Federation of Self Help Groups or Joint Liability Groups and
              conduct or assist in conducting workshop, seminar, exhibition,
              training, conference, road show etc. activities for the benefits
              of members or managers of Self Help Groups or Joint Liability
              Groups and to frame Rules, Sub Rules & regulations for them, and
              to introduce welfare schemes.
            </li>
            &nbsp;
            <li style={listfont}>
              To print, publish, and distribute books, periodicals, leaflets,
              brochures and papers for the promotion of the objects of the Trust
              and/or “Author of the Trust / Settlor Organization”.
            </li>
            &nbsp;
            <li style={listfont}>
              To organize and conduct programmes for providing employment to
              youths on it's own or in collaboration with other agencies or
              association
            </li>
            &nbsp;
            <li style={listfont}>
              To organize and conduct programmes for educating public opinion
              regarding eradication of under employment, poverty and social
              evils like illiteracy, dowry, casteism, consuming alcohol, drugs,
              tobacco, gutka, and/or corruption etc....
            </li>
            &nbsp;
            <li style={listfont}>
              To Associate or have collaboration with other Governmental or
              Non-Governmental Organizations in conducting charitable activities
              which are similar to the objects of the Trust.
            </li>
            &nbsp;
            <li style={listfont}>
              To encourage use of non conventional energy including solar, gober
              gas, biogas to save electric power and to associate with suppliers
              of such equipments to provide information and awareness among
              public.
            </li>
            &nbsp;
            <li style={listfont}>
              To establish information and facilitation centre to help public
              regarding various social facilities provided by Government like
              widow pension, disabilities pension, Bhagyalxmi scheme, Sandhya
              Suraksha, Sakala, PAN, Aadhar Card, Epic Card, Senior citizen
              Card, etc...
            </li>
            &nbsp;
            <li style={listfont}>
              To organize and conduct program for empowering Women, Tribal
              Groups, Forest Dwellers, Schedule Caste and/or Schedule Tribe or
              for other backward classes.
            </li>
            &nbsp;
            <li style={listfont}>
              To arrange, assist and organize community awareness programmes
            </li>
            &nbsp;
          </ol>
          <div>
            <h1 style={aboutStyle3}>Trustees</h1>
          </div>
          <div>
            <img src={sathish} alt="SRS" style={{ ...imgPrakash }} />
            <img src={vasanth} alt="SRS" style={{ ...imgHemanth }} />
            <img src={deviprasad} alt="SRS" style={{ ...imgRamesh }} />
            <p style={paraStyle6}>
              Devisadana, Punacha, Bantwal.
              <br />
              <br />
              Ph: 08255-268229
              <br />
              <br />
              08255-276319
              <br />
              <br />
              Mob: +91-9448696108
            </p>
            <p style={paraStyle7}>
              Darbe, Puttur-574202.
              <br />
              <br />
              Dakshina Kannada, Karnataka, India.
              <br />
              <br />
              Mob: +91-9449773579
            </p>
            <p style={paraStyle8}>
              S/o Srinivasa sharma K.
              <br />
              <br />
              Sharma Farms, Kallaje House,
              <br />
              <br />
              Punacha Post & Village, Bantwal Taluk D. K.
              <br />
              <br />
              Mob: +91-9886652495
            </p>
          </div>
          <div>
            <img src={prabhu1} alt="SRS" style={{ ...imgPrakash }} />
            <img src={chandra} alt="SRS" style={{ ...imgHemanth }} />
            <p style={paraStyle9}>
              S/o Late. A Thimmappaiah Prabhu,
              <br />
              <br />
              #4/944/3, Souza Layout,
              <br />
              <br />
              Darbe, Puttur D K – 574202
              <br />
              <br />
              Mob: +91-9449773630
            </p>
            <p style={paraStyle10}>
              S/o Janardhana Nayak,
              <br />
              <br />
              Poroli House, Bajathur Village,
              <br />
              <br />
              Puttur, D K,
              <br />
              <br />
              Mob: +91-9448857284
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
