import React,{useEffect, useState} from 'react'
import axios from 'axios'
import UserNav from './UserNav'
// import Image2 from "../assests/SaraswathiSahakari-banner-2.jpg"
import report from "../assests/report.jpg"
import Option from './Option'


const imgAboutBaStyle = {
  maxWidth: "920px",
  marginTop: "-359px",
  paddingTop: "50px",
  height: "319px",
  marginLeft: "485px",
};

const imgAboutBadivStyle = {
  marginTop: "-17px",
}

const hrStyle = {
    border: "none",
    borderTop: "2px solid #8e0c56",
    marginTop: "10px",
  };
export default function Notification() {
    const [allImage, setAllImage] = useState(null);
    useEffect(() =>{
        getpdf();
    }, []);
    
    const getpdf = async()=>{
        const result = await axios.get("http://localhost:2045/endpoint/pdf");
        console.log(result.data.data);
        setAllImage(result.data.data);
    };

    const showpdf=(pdf)=>{
        window.open(`http://localhost:2045/files/${pdf}`, "_blank", "noreferrer");
    }

   
  return (
    <div   >
        <UserNav />
        <Option />
        <div style={imgAboutBadivStyle}>
          <img src={report} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
      {/* <div className='text-center'>
      <img alt="img" src={Image2} style={{ width: '100%', display: 'block' }} />
      </div> */}
  
     
     
     
        <div className="container">
          <div className="row justify-content-center">
      
            <div className="col-md-10">
            <h3 style={{ color: "purple", textAlign: "center" }}>
    Annual Report
        </h3>
              <div >
                <div className="card">
                
                 
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Document
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                         View Document
                      </th>
                   
                    </tr>
                  </thead>
                  <tbody className="text-center">
                  {allImage==null?"":allImage.map(data=>{
                return(
                    <tr key={data._id}>
                   
                       <td>{data.title}</td>
       
                      
                        <td>
                        
                         
                        
                <button className='btn btn-primary'onClick={()=>showpdf(data.pdf)}>View Pdf</button>
                     
                        </td>

                       
                      </tr>
       

                )
            })}
                 
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    

    </div>
  )
}
