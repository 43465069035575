import React from 'react'
import '../Components/UserNavStyle.css';
import Logo from "../assests/Saraswathi-Logo.png"

export default function UserNav() {
  return (
    <div className='navbar1'>
       
       <nav className="navbar navbar-expand-lg ">
  <div className="container-fluid">
  <img alt="Saraswathi Sahakari banner 1" src={Logo}  />
    <a className="navbar-brand head" href="/"><b>Shri Saraswathi Credit Souharda Sahakari Sangha LTD. Puttur, DK</b></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        
      <li className="nav-item">
          <a className="nav-linkss" href="/">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="about">About</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="orgnization">Organization</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="financial">Financial Details</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="service">Our Services</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="subsidiary">Subsidiary</a>
        </li>
       
        <li className="nav-item">
          <a className="nav-linkss" href="gallery">Gallery</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="contact">Contact</a>
        </li>
        <li className="nav-item">
          <a className="nav-linkss" href="login">Login</a>
        </li>
      {/* <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Fleet List
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/break-bulk">Break Bulk</a></li>
            <li><a className="dropdown-item" href="/list-pctc">PCTC</a></li>
          
          </ul>
        </li> */}
       
       
        {/* <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Intra Asia
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-asia">Cosmic Polaris</a></li>
            <li><a className="dropdown-item" href="/list-glanz">Glanz Coral</a></li>
            <li><a className="dropdown-item" href="/list-seiyo">Seiyo Goddess</a></li>
            <li><a className="dropdown-item" href="/list-haiduong">Hai Duong 68</a></li>
            <li><a className="dropdown-item" href="/list-bright">Bright Coral</a></li>
            <li><a className="dropdown-item" href="/list-cosmic">Cosmic OAK</a></li>
            <li><a className="dropdown-item" href="/list-bari">Bari Diamond</a></li>
            <li><a className="dropdown-item" href="/list-phoenix">Phoenix Coral</a></li>
            <li><a className="dropdown-item" href="/list-malaysia">PCTC</a></li>
          
           
          </ul>
        </li> */}

        {/* <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Bay of Bengal
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-precious">Precious Coral</a></li>
            <li><a className="dropdown-item" href="/list-spm">SPM Bangkok</a></li>
            <li><a className="dropdown-item" href="/list-diamond">Diamond Star</a></li>
            <li><a className="dropdown-item" href="/list-venus">Venus Triumph</a></li>
            <li><a className="dropdown-item" href="/list-panda">21 Panda</a></li>
            <li><a className="dropdown-item" href="/list-Keio">Keio Coral</a></li>
            <li><a className="dropdown-item" href="/list-sun">Sun Unity 36</a></li>
             <li><a className="dropdown-item" href="/list-unity">Sun Unity</a></li> 
            <li><a className="dropdown-item" href="/list-hosei">Hosei Crown</a></li>
            <li><a className="dropdown-item" href="/list-manami">Manami Coral</a></li>
            <li><a className="dropdown-item" href="/list-horizon">Horizon 9</a></li>
          
           
          </ul>
        </li> */}
      
        {/* <li className="nav-item dropdown">
          <a className="nav-linkss dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Master
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/list-vessels">Vessels</a></li>
          
           
          </ul>
        </li> */}
       
        
      </ul>
   
    </div>
  </div>
</nav>
    </div>

  )
}
