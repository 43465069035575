import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Image1() {
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:2045/endpoint/images').then((res) => {
      console.log(res.data); // Check if the data is received from the backend
      setImages(res.data);
    }).catch(error => {
      console.error('Error fetching images:', error); // Log any errors
    });
  }, []);

  const onChangeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('myImage', image);
    axios.post('http://localhost:2045/endpoint/upload', formData).then((res) => {
      console.log(res.data);
      setImages([...images, res.data.image]); // Change here
    });
  };

  return (
    <div>
      <h1>Image Upload</h1>
      <form onSubmit={onSubmitHandler}>
        <input type="file" name="myImage" onChange={onChangeHandler} />
        <button type="submit">Upload</button>
      </form>
      <div>
        {images.map((image, index) => (
          <img key={index} src={`http://localhost:2045/endpoint/${image.imageData}`} alt={`Image ${index}`} style={{ width: '100px' }} />
        ))}
      </div>
    </div>
  )
}
