import React , {useState}from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";
import Navbar from './Navbar'

export default function FinancialList() {
    const [year, setYear] = useState()
    const [members, setMembers] = useState()
    const [share_capital, setShare_Capital] = useState()
    const [deposits, setDeposits] = useState()
    const [loans, setLoans] = useState()
    const [reserves, setReserves] = useState()
    const [net_profit, setNet_Profit] = useState()
    const [dividend, setDividend] = useState()


    
    const navigate = useNavigate()

    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2045/endpoint/add-financial', {year, members, share_capital, deposits, loans, reserves, net_profit, dividend})
        .then(result => {console.log(result)
            navigate('/financial-list')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
      <Navbar ></Navbar>
      <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Submit}> 
                <div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Year</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setYear(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Members</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setMembers(e.target.value)} />

                    </div>
                    </div>
                  
</div>
         
<div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Share Capital</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setShare_Capital(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Deposits</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setDeposits(e.target.value)} />

                    </div>
                    </div>
                  
</div>


<div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Loans</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setLoans(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Reserves</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setReserves(e.target.value)} />

                    </div>
                    </div>
                  
</div>
                    
<div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Net Profit</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setNet_Profit(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Dividend</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setDividend(e.target.value)} />

                    </div>
                    </div>
                  
</div>         
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
    </div>
    </div>
  )
}
