import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function UpdateMonthly() {
  const {id} = useParams()

    const [date, setDate] = useState()
    const [members, setMembers] = useState()
    const [working_capital, setWorking_Capital] = useState()
    const [deposits, setDeposits] = useState()
    const [loans, setLoans] = useState()
    const [share, setShare] = useState()

    
    useEffect(() =>{
      axios.get('http://localhost:2045/endpoint/monthly/'+id)
      .then(result => {console.log(result)
          setMembers(result.data.members)
          setDate(result.data.date)
          setWorking_Capital(result.data.working_capital)
          setDeposits(result.data.deposits)
          setLoans(result.data.loans)
          setShare(result.data.share)
          
     
      
      })
      .catch(err => console.log(err))
    }, [id])

  const navigate = useNavigate()
  const Update =(e) => {
      e.preventDefault();
          axios.put('http://localhost:2045/endpoint/update-monthly/'+id, {date, members, working_capital, deposits, loans, share})
          .then(result => {console.log(result)
              navigate('/monthly-list')
  
      })
          .catch(err => console.log(err))
  
      }
  return (
    <div>
      <Navbar ></Navbar>
       <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 >Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update}> 
         
  
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Year</b></label>
                        <input type="date" formControlName="category_desc" value={date} class="form-control" onChange={(e)=> setDate(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Members</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={members} onChange={(e)=> setMembers(e.target.value)} />

                    </div>
                    </div>
                    </div>
                  
                

                 
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b></b></label>
                        <input type="text" formControlName="category_desc" value={working_capital} class="form-control" onChange={(e)=> setWorking_Capital(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Deposits</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={deposits} onChange={(e)=> setDeposits(e.target.value)} />

                    </div>
                    </div>
                    </div>
            
                   
                    
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Loans</b></label>
                        <input type="text" formControlName="category_desc" value={loans} class="form-control" onChange={(e)=> setLoans(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Share</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={share} onChange={(e)=> setShare(e.target.value)} />

                    </div>
                    </div>
                    </div>
            

                     <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/monthly-list" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>


    </div>

  )
}
