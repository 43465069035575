import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
export default function AddLoan() {
  const [loan_name, setLoan_Name] = useState();

  const navigate = useNavigate();

  const Submit = (e) => {
  
    e.preventDefault();
    axios
      .post("https://akms.org.in:2045/endpoint/add-loan", { loan_name }) // Remove the port
      .then((result) => {
        console.log(result);
        navigate("/loan-list");
      })
      .catch((err) => console.log(err));
  };


  // const Submit = (e) => {
  //   e.preventDefault();
  //   axios
    
  //     .post("https://akms.org.in:2045/endpoint/add-loan", { loan_name })
  //     .then((result) => {
  //       console.log(result);
  //       navigate("/loan-list");
  //     })
  //     .catch((err) => console.log(err));
  // };
  return (
    <div>
      <Navbar></Navbar>
      <div class="py-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="card shadow">
                <div class="card-header">
                  <h2 className="text-center">Interest Rates </h2>
                </div>
                <div class="card-body">
                  <form onSubmit={Submit}>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group mb-3">
                          <label for="">
                            <b>Loan Name</b>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setLoan_Name(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="from-group text-center">
                      <button type="submit" class="btn btn-success">
                        Save
                      </button>
                      &nbsp;
                      <Link class="btn btn-danger" to="/">
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
