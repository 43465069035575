import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar'
import axios from 'axios'
import { Link } from "react-router-dom";


export default function UpdateInterest() {
    const {id} = useParams()

    const [rate_of_interest, setRate_of_interest] = useState()
    const [description, setDescription] = useState()


    useEffect(() =>{
        axios.get('http://localhost:2045/endpoint/bank/'+id)
        .then(result => {console.log(result)
            setRate_of_interest(result.data.rate_of_interest)
            setDescription(result.data.description)
          
       
        
        })
        .catch(err => console.log(err))
      }, [id])

    const navigate = useNavigate()
    const Update =(e) => {
        e.preventDefault();
            axios.put('http://localhost:2045/endpoint/update-bank/'+id, {rate_of_interest, description})
            .then(result => {console.log(result)
                navigate('/')
    
        })
            .catch(err => console.log(err))
    
        }
  return (
    <div>
       <Navbar ></Navbar>
       <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 >Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Update}> 
         
  
<div className='row'>
<div class="col-md-8">
                    <div class="form-group mb-3">
                        <label for=""><b>Description</b></label>
                        <input type="text" formControlName="category_desc" value={description} class="form-control" onChange={(e)=> setDescription(e.target.value)} />

                    </div>
                    </div>
                  

                  



                    <div class="col-md-4">

                    <div class="form-group mb-3">
                        <label for=""><b>Rate of Interest</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" value={rate_of_interest} onChange={(e)=> setRate_of_interest(e.target.value)} />

                    </div>
                    </div>
                    </div>
                  
                
                   
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
</div>

    </div>
  )
}
