import React,{useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './LoginStyle.css'
import axios from 'axios'
import './NavbarStyle.css';

export default function Login() {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
      
      const navigate = useNavigate()
  
      const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2045/endpoint/', {email, password})
        .then(res => { 
          if(res.data==="exist"){
          navigate('/home',{state:{id:email}})
        }
        else if(res.data==="notexist"){
        alert("Email is wrong")
        } 
  
    })
    .catch(e=>{
      alert("wrong")
      console.log(e);
    })
        .catch(err => console.log(err))
  
    }
  return (
    <div>
         <div className='navbar1'>
       
       <nav className="navbar navbar-expand-lg ">
  <div className="container-fluid">
    <a className="navbar-brand head" href="/"><h2><b>Shri Saraswathi Credit Souharda Sahakari Sangha LTD. Puttur, DK</b></h2> </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
       </ul>
   
    </div>
  </div>
</nav>
    </div>
      <div className='manu'>
           
           <form className='formStyle' onSubmit={Submit}>
           <h4>Login </h4><br />
           <input type="email" className='form-control' placeholder='Admin Email' required onChange={(e)=>{setEmail(e.target.value)}} />
           <br/>
           <input type="password" className='form-control'placeholder='Password' required onChange={(e)=>{setPassword(e.target.value)}} />
           <br />
           <button className='btn btn-primary' type="submit">Submit</button>&nbsp;
           <Link class="btn btn-danger" to="/pdf-list" >Cancel</Link>
                                
               </form>      
               </div> 
    </div>
  )
}
