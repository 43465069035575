import React from 'react'
import UserNav from './UserNav'
import Option from './Option'
// import president from "../assests/president.jpg"
import Orgnization1 from "../assests/management.png"
import presidentimage from "../assests/presidentimage.png"




const imgPMessageBadivStyle = {
    marginTop: "-402px",
  };
  
  const imgPMessageBaStyle = {
    // width: "810px", // Explicitly set width
    // height: "auto", // Maintain aspect ratio
    // marginTop: "-1000px",
    // paddingTop: "50px",
    // marginLeft: "485px",


    maxWidth: "920px",
    marginTop: "62px",
    height: "319px",
    paddingTop: "50px",
    marginLeft: "485px",
  };
  
  const hrStyle = {
    border: "none",
    borderTop: "2.5px solid #8e0c56",
    marginTop: "30px",
  };
  
  const LoansStyle = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "30px",
    marginLeft: "210px",
    color: "#8e0c56",
  };
  
  const imgPresident = {
    width: "130px",
    height: "auto",
    marginLeft: "210px",
    marginTop: "5px",
    float: "left", // Add float left to position the image next to the text
    marginRight: "20px", // Add margin-right for spacing
  };
  
  const paraStyle = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    textAlign: "justify",
    lineHeight: "1.7", // Adjust line height as needed
    paddingTop: "15px", // Add padding top to align with the image
    paddingLeft: "200px",
    paddingRight: "200px",
    margin: "10px",
  };
  
export default function President() {
  return (
    <div>
        <UserNav />
      <Option />
      {/* <div style={imgPMessageBadivStyle}>
        <img src={president} alt="About Us" style={imgPMessageBaStyle} />
      </div> */}
         <div style={imgPMessageBadivStyle}>
          <img src={Orgnization1} alt="About Us" style={imgPMessageBaStyle} />
        </div>
      <div>
        <hr style={hrStyle} />
      </div>
      <div>
        <h1 style={LoansStyle}>President's Message</h1>
      </div>
      <div>
        <img src={presidentimage} alt="President" style={imgPresident} />
        <p style={paraStyle}>
          It is with great pleasure that I address this message to you on behalf
          of Shri Saraswathi Credit Souharda Sahakari Ltd. Puttur D. K.
          <br />
          <br />
          The Co-Operative movement is having historical background in India. It
          is the root of Indian life. Co-Operatives are for all class of people.
          Further, it was always champion of the disadvantaged in the society.
          Today credit societies play an important role in economic as well as
          social development of the country. Apart from that they are promoting
          development and to fulfill the concept of financial inclusion.
          <br />
          <br />
          Shri Saraswathi Credit Souharda Sahakari strongly believes in
          Co-Operative principles for the welfare of its members and strives to
          render various financial services under one roof and to become
          financial super market, where in all financial needs of the people are
          fulfilled. Thanks to our large number of members for their continued
          support for the growth of the Sahakari. We expect the same in future
          too.
        </p>
      </div>
    </div>
  )
}
