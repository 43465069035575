import React, { useEffect, useState } from "react";
import Navbar from './Navbar'
import axios from "axios";
import '../Admin/Home4.css'
import { Link } from "react-router-dom";
export default function ADDFinancial() {
    const [financial, setFinancial] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:2045/endpoint/get-financiall")
        .then((result) => setFinancial(result.data))
        .catch((err) => console.log(err));
    }, []);
  
    const handelDelete = (id) => {
      // Prompt the user with a confirmation dialog
      const confirmDelete = window.confirm("Are you sure you want to delete?");
    
      // If the user confirms, proceed with the delete operation
      if (confirmDelete) {
        axios
          .delete("http://localhost:2045/endpoint/soft-financial/" + id)
          .then((res) => {
            console.log(res);
            window.location.reload();
          })
          .catch((errr) => console.log(errr));
      }
    };
    
  return (
    <div className="home-container">
    <Navbar ></Navbar>
    <div>
        <br></br>
    <h2 className="head" style={{ color: "black", textAlign: "center" }}>
    Interest Rates
    </h2>
    <br></br>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-15">
          <div className="card">
            <div className="card-body">
              <a href="add-financial" className="btn btn-secondary float-end">
                Add Financial
              </a>
              <br />
              <br />
             
              <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                  Year
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Members
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Share Capital
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Deposits
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Loans
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Reserves
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Net Profit
                  </th>
                  <th
                    scope="col"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    Dividend %
                  </th>
                 <th  
                 scope="col"
                    style={{ color: "black", textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {financial.map((item) => (
                  <tr key={item._id}>
                   <td>{item.year}</td>
            <td>{item.members}</td>
            <td>{item.share_capital}</td>
            <td>{item.deposits}</td>
            <td>{item.loans}</td>
            <td>{item.reserves}</td>
            <td>{item.net_profit}</td>
            <td>{item.dividend}</td>
                  
                    <td>
                    
                      <Link to={`/update-financial/${item._id}`} className="btn btn-success">Edit</Link>&nbsp;
                      <button
                        className="btn btn-danger"
                        onClick={(e) => handelDelete(item._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}
