import React , {useState}from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";
import Navbar from './Navbar'
export default function AddMonthlyFinancial() {
    const [date, setDate] = useState()
    const [members, setMembers] = useState()
    const [working_capital, setWorking_Capital] = useState()
    const [deposits, setDeposits] = useState()
    const [loans, setLoans] = useState()
    const [share, setShare] = useState()



    
    const navigate = useNavigate()

    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2045/endpoint/add-monthly', {date, members, working_capital, deposits, loans, share})
        .then(result => {console.log(result)
            navigate('/monthly-list')

    })
        .catch(err => console.log(err))

    }
   
  return (
    <div>
      <Navbar ></Navbar>
      <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Submit}> 
                <div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Date</b></label>
                    <input type="date" className='form-control' onChange={(e)=> setDate(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Members</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setMembers(e.target.value)} />

                    </div>
                    </div>
                  
</div>
         
<div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Working Capital</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setWorking_Capital(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Deposits</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setDeposits(e.target.value)} />

                    </div>
                    </div>
                  
</div>

<div class="col-md-4">
    <div class="form-group mb-3">
        <label for=""><b>Deposits</b></label>
        <select 
            formControlName="category_desc" 
            class="form-control" 
            multiple 
            onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                setDeposits(selectedOptions);
            }}>
            <option value="savings">Savings</option>
            <option value="checking">Checking</option>
            <option value="fixed">Fixed Deposit</option>
            <option value="recurring">Recurring Deposit</option>
        </select>
    </div>
</div>



<div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Loans</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setLoans(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Share</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setShare(e.target.value)} />

                    </div>
                    </div>
                  
</div>
   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/monthly-list" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
    </div>
    </div>

  )
}
