import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Displays.css'
export default function DisplayImage() {
  const [imagess, setImagess] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("http://localhost:2045/endpoint/services")
      .then((response) => {
        setImagess(response.data.data);
      })
      .catch((error) => {
        setError('Error fetching images. Please try again later.');
        console.error('Error fetching images:', error);
      });
  }, []);

  return (
    <div>
      {error ? (
        <div>{error}</div>
      ) : (
        <div  className='flex'>
          {imagess.length > 0 ? (
            imagess.map((image, index) => (
              <div key={index + 1} className='card'>
          <img src={`http://localhost:2045/${image?.imageUrl}`} width="200" height="200" />

                {console.log(`http://localhost:2045/${image?.imageUrl}`)}
                {/* Optionally, display other image information */}
                {/* <div>{image.imageUrl}</div> */}
              </div>
            ))
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      )}
    </div>
  );
}
