import AddInterest from "./Admin/AddInterest";
import DisplayImage from "./Admin/DisplayImage";
import Home from "./Admin/Home";
import Image1 from "./Admin/Image1";
import ImageUpload from "./Admin/ImageUpload";
import Login from "./Admin/Login";
import PdfUpload from "./Admin/PdfUpload";
import Pdflist from "./Admin/Pdflist";
import Signup from "./Admin/Signup";
import UpdateInterest from "./Admin/UpdateInterest";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import UserHome from "./Components/UserHome";
import Notification from "./Components/Notification";
import Gallery from "./Components/Gallery";
import About from "./Components/About";
import President from "./Components/President";
import Interest from "./Components/Interest";
import AddLoan from "./Admin/AddLoan";
import LoanList from "./Admin/LoanList";
import UpdateLoan from "./Admin/UpdateLoan";
import Loans from "./Components/Loans";
import Award from "./Components/Award";
import Orgnization from "./Components/Orgnization";
import Services from "./Components/Services";
import Subsidiary from "./Components/Subsidiary";
import ADDFinancial from "./Admin/ADDFinancial";
import FinancialList from "./Admin/FinancialList";
import UpdateFinancial from "./Admin/UpdateFinancial";
import Financial from "./Components/Financial";
import Contact from "./Components/Contact";
import AddMonthlyFinancial from "./Admin/AddMonthlyFinancial";
import MonthlyList from "./Admin/MonthlyList";
import UpdateMonthly from "./Admin/UpdateMonthly";
import Brochure from "./Components/Brochure";
import AddBroucher from "./Admin/AddBroucher";
import ListBrochure from "./Admin/ListBrochure";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/add-inst" element={<AddInterest />}></Route>
        <Route path="/update-inst/:id" element={<UpdateInterest />}></Route>
        <Route path="/image" element={<ImageUpload />}></Route>
        <Route path="/display" element={<DisplayImage />}></Route>
        <Route path="/img" element={<Image1 />}></Route>
        <Route path="/pdf" element={<PdfUpload />}></Route>
        <Route path="pdf-list" element={<Pdflist />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/add-loan" element={<AddLoan />}></Route>
        <Route path="/loan-list" element={<LoanList />}></Route>
        <Route path="/update-loan/:id" element={<UpdateLoan />}></Route>
        <Route path="/financial-list"  element={<ADDFinancial />}></Route>
        <Route path="/add-financial" element={<FinancialList />}></Route>
        <Route path="/update-financial/:id" element={<UpdateFinancial />}></Route>
        <Route path="/" element={<UserHome />}></Route>
        <Route path="/notify" element={<Notification />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/president" element={<President />}></Route>
        <Route path="/interest" element={<Interest />}></Route>
        <Route path="/loans" element={<Loans />}></Route>
        <Route path="/award" element={<Award />}></Route>
        <Route path="/orgnization" element={<Orgnization />}></Route>
        <Route path="/service" element={<Services />}></Route>
        <Route path="/subsidiary" element={<Subsidiary />}></Route>
        <Route path="/financial" element={<Financial />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/monthly-add" element={<AddMonthlyFinancial />}></Route>
        <Route path="/monthly-list" element={<MonthlyList />}></Route>
        <Route path="/update-monthly/:id" element={<UpdateMonthly />}></Route>
        <Route path="/brochure" element={<Brochure />}></Route>
        <Route path="/add-brochure" element={<AddBroucher />}></Route>
        <Route path="/list-brochure" element={<ListBrochure />}></Route>
        <Route path="/brochure" element={<Brochure />}></Route>
      
      
   
      </Routes>
    </div>
  );
}

export default App;
