import React, { useEffect, useState } from "react";
import Navbar from './Navbar'
import axios from "axios";
import '../Admin/Home4.css'
import { Link } from "react-router-dom";
export default function Home() {
    const [bank, setBank] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:2045/endpoint/get-bankk")
        .then((result) => setBank(result.data))
        .catch((err) => console.log(err));
    }, []);
  
    const handelDelete = (id) => {
      // Prompt the user with a confirmation dialog
      const confirmDelete = window.confirm("Are you sure you want to delete?");
    
      // If the user confirms, proceed with the delete operation
      if (confirmDelete) {
        axios
          .delete("http://localhost:2045/endpoint/soft-bank/" + id)
          .then((res) => {
            console.log(res);
            window.location.reload();
          })
          .catch((errr) => console.log(errr));
      }
    };
    
  return (
    <div className="home-container">
        <Navbar ></Navbar>
        <div>
            <br></br>
        <h2 className="head" style={{ color: "black", textAlign: "center" }}>
        Interest Rates
        </h2>
        <br></br>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-15">
              <div className="card">
                <div className="card-body">
                  <a href="add-inst" className="btn btn-secondary float-end">
                    Add Interest
                  </a>
                  <br />
                  <br />
                 
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                         Rate of Interest
                      </th>
                     <th  
                     scope="col"
                        style={{ color: "black", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {bank.map((item) => (
                      <tr key={item._id}>
                       <td>{item.description}</td>
                <td>{item.rate_of_interest}</td>
                      
                        <td>
                        
                          <Link to={`/update-inst/${item._id}`} className="btn btn-success">Edit</Link>&nbsp;
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handelDelete(item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
