import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom'
import Navbar from './Navbar'
import { Link } from "react-router-dom";
export default function ImageUpload() {
  const [image, setImage] = useState(null);
  const [name, setName] = useState();

  const navigate = useNavigate()
  const onSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name); // The first parameter is the field name expected by the server

    try {
      const res = await axios.post(
        'http://localhost:2045/endpoint/add-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure the correct content type
            'Authorization': localStorage.getItem('token'),
          },
        }
      );

      console.log(res.data);
      navigate('/display')

      if (res.data.code === 403 && res.data.message === 'Token Expired') {
        localStorage.setItem('token', null);
      }
    } catch (err) {
      console.error('Error uploading the image:', err);
    }
  };

  return (
    
    <div>
       <Navbar ></Navbar>
       <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Upload Images </h2>
            </div>
            <div class="card-body">
                <form onSubmit={onSubmit}> 
              
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Select Image</b></label>
                    <br></br>
                    <input type="file"  className='form-control' onChange={(e) => setImage(e.target.files[0])} />
                </div>
</div>
<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Members</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setName(e.target.value)} />

                    </div>
                    </div>

                  

                 


                    
                   
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
    </div>
      {/* <h2>Upload File</h2>
      <form onSubmit={onSubmit}>
        <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        <button type="submit">Upload</button>
      </form> */}
    </div>
  );
}
