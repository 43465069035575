import React,{useState, useEffect} from 'react'
import UserNav from './UserNav'
import interest from "../assests/interest.jpg"
import Option from './Option';
import axios from "axios";

const imgAboutBaStyle = {
  maxWidth: "920px",
  marginTop: "-359px",
  paddingTop: "50px",
  height: "319px",
  marginLeft: "485px",
};

const imgAboutBadivStyle = {
  marginTop: "-17px",
}

const hrStyle = {
    border: "none",
    borderTop: "2px solid #8e0c56",
    marginTop: "10px",
  };
export default function Interest() {

    const [bank, setBank] = useState([]);

    useEffect(() => {
      axios
        .get("http://localhost:2045/endpoint/get-bankk")
        .then((result) => setBank(result.data))
        .catch((err) => console.log(err));
    }, []);
  
  return (
    <div>
      <UserNav />
      <Option />
      <div>
      <a href="brochure" className="btn btn-secondary float-end">
                Brochure
              </a>
        <div style={imgAboutBadivStyle}>
          <img src={interest} alt="About Us" style={imgAboutBaStyle} />
        </div>
        
        <div>
          <hr style={hrStyle} />
        </div>
       
        <div className="container">
       
          <div className="row justify-content-center">
          <h3 style={{color:"purple",  textAlign: "center"}}>Interest Rate</h3>
            <div className="col-md-6">
              <div >
                
                <div className="card-body">
                <h4 style={{ backgroundColor:"purple", textAlign: "center", color:"#fff" }}> Attractive interest rates on deposits:</h4>
                 
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "left" }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "left" }}
                      >
                         Rate of Interest
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    {bank.map((item) => (
                      <tr key={item._id}>
                       <td>{item.description}</td>
                <td>{item.rate_of_interest}</td>
                      
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
