import React from 'react'
import UserNav from './UserNav'
import service1 from "../assests/services.jpg"
import Option from './Option';
import { FaHandPointRight } from "react-icons/fa";

const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };


    
const aboutStyle = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "20px",
    marginLeft: "235px",
    color: "#8e0c56",
  };
  
  const paraStyle = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "280px",
    paddingRight: "300px",
    paddingTop: "25px",
  };
  
//   const Content = {
//     marginTop: "-440px",
//   };
  
  const HandPointStyle = {
    color: "#8e0c56",
    fontSize: "25px",
    marginLeft: "230px",
    marginTop: "-100px",
  };
  
  const PointIcon1 = {
    marginTop: "-310px",
  };
  
  const PointIcon2 = {
    marginTop: "35px",
  };
  
  const PointIcon3 = {
    marginTop: "39px",
  };
  
  const PointIcon4 = {
    marginTop: "26px",
  };
  
  const PointIcon5 = {
    marginTop: "44px",
  };
  
  const PointIcon6 = {
    marginTop: "55px",
  };

export default function Services() {
  return (
    <div>
      <UserNav />
      <Option />
      <div>
    
        <div style={imgAboutBadivStyle}>
          <img src={service1} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
        <div>
          <h1 style={aboutStyle}><b>Services</b></h1>
          <p style={paraStyle}>
            Various deposit schemes at attractive interest rate.
            <br />
            <br />
            Various loan schemes at competitive interest rate.
            <br />
            <br />
            E-Stamping facility.
            <br />
            <br />
            PAN card facility.
            <br />
            <br />
            Interest for Senior citizens, Physically challenged, Widows,
            Soldiers and institutional deposits (added interest will be given.)
            <br />
            <br />
            Safe Locker facility.
          </p>
          <div style={PointIcon1}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
          <div style={PointIcon2}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
          <div style={PointIcon3}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
          <div style={PointIcon4}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
          <div style={PointIcon5}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
          <div style={PointIcon6}>
            <FaHandPointRight style={HandPointStyle} />
          </div>
        </div>
        </div>
    </div>
  )
}
