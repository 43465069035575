import React,{useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './Navbar'
import axios from 'axios'
import { Link } from "react-router-dom";

export default function UpdateLoan() {

    const {id} = useParams()

    const [loan_name, setLoan_Name] = useState()
  


    useEffect(() =>{
        axios.get('http://localhost:2045/endpoint/loan/'+id)
        .then(result => {console.log(result)
            setLoan_Name(result.data.loan_name)
          
          
       
        
        })
        .catch(err => console.log(err))
      }, [id])

    const navigate = useNavigate()
    const Update =(e) => {
        e.preventDefault();
            axios.put('http://localhost:2045/endpoint/update-loan/'+id, {loan_name})
            .then(result => {console.log(result)
                navigate('/loan-list')
    
        })
            .catch(err => console.log(err))
    
        }
  return (
    <div>
    <Navbar ></Navbar>
    <div class="py-5">
<div class="container">
 <div class="row justify-content-center">

     <div class="col-md-6">
 
        <div class="card shadow">
   
         <div class="card-header">
             <h2 >Loans </h2>
         </div>
         <div class="card-body">
             <form onSubmit={Update}> 
      

<div className='row'>
<div class="col-md-8">
                 <div class="form-group mb-3">
                     <label for=""><b>Loan Name</b></label>
                     <input type="text" formControlName="category_desc" value={loan_name} class="form-control" onChange={(e)=> setLoan_Name(e.target.value)} />

                 </div>
                 </div>
               

               



                 </div>
               
             
                
                

                 <div class="from-group text-center">
                     <button type="submit" class="btn btn-success">Save</button>&nbsp;
                     <Link class="btn btn-danger" to="/" >Cancel</Link>
                  

                 </div>
</form>


             </div>
            </div>
         </div>
     </div>
 </div>
</div>

 </div>

  )
}
