import React , {useState}from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";
import Navbar from './Navbar'

export default function AddInterest() {
    const [rate_of_interest, setRate_of_interest] = useState()
    const [description, setDescription] = useState()

 



    const navigate = useNavigate()

    const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2045/endpoint/add-bank', {rate_of_interest, description})
        .then(result => {console.log(result)
            navigate('/')

    })
        .catch(err => console.log(err))

    }
  return (
    <div>
          <Navbar ></Navbar>
      <div class="py-5">
<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-6">
    
           <div class="card shadow">
      
            <div class="card-header">
                <h2 className='text-center'>Interest Rates </h2>
            </div>
            <div class="card-body">
                <form onSubmit={Submit}> 
                <div class="row">
                  <div class="col-md-7">
                    <div class="form-group mb-3">
                    <label for=''><b>Description</b></label>
                    <input type="text" className='form-control' onChange={(e)=> setDescription(e.target.value)} />
                </div>
</div>

<div class="col-md-4">
                    <div class="form-group mb-3">
                        <label for=""><b>Rate of Interest</b></label>
                        <input type="text" formControlName="category_desc" class="form-control" onChange={(e)=> setRate_of_interest(e.target.value)} />

                    </div>
                    </div>
                  
</div>
                    {/* <div class="form-group mb-2">
                        <label for="">Category Name</label>
                        <input type="text"  class="form-control" />

                    </div>
 */}


                    
                   
                   

                    <div class="from-group text-center">
                        <button type="submit" class="btn btn-success">Save</button>&nbsp;
                        <Link class="btn btn-danger" to="/" >Cancel</Link>
                     

                    </div>
</form>


                </div>
               </div>
            </div>
        </div>
    </div>
    </div>
</div>

  )
}
