import React,{useEffect, useState} from 'react'
import axios from 'axios'
import UserNav from './UserNav'

export default function Brochure() {
    const [brochure, setBrochure] = useState(null);
    useEffect(() =>{
        getpdf();
    }, []);
    
        const getpdf = async()=>{
            const result = await axios.get("http://localhost:2045/endpoint/brochure");
            console.log(result.data.data);
            setBrochure(result.data.data);
        };
    
        const showpdf=(pdf)=>{
            window.open(`http://localhost:2045/files/${pdf}`, "_blank", "noreferrer");
        }
    
  return (
    <div className='uploaded'>
      <UserNav />
   {/* <h4>pdf</h4> */}
   {/* <div className='output-div'>
       {allImage==null?"":allImage.map(data=>{
           return(
<div className='inner-div'>
           <h6>title: {data.title}</h6>
           <button className='btn btn-primary'onClick={()=>showpdf(data.pdf)}>Show Pdf</button>
       </div>
           )
       })}
       
   </div> */}

<div>
       <br></br>
   <h2 className="head" style={{ color: "black", textAlign: "center" }}>
 Brochure 
   </h2>
   <br></br>
   <div className="container">
     <div className="row justify-content-center">
       <div className="col-md-9">
         <div className="card">
           <div className="card-body">
          
             <br />
             <br />
            
             <table className="table table-bordered table-striped">
             <thead>
               <tr>
                 <th
                   scope="col"
                   style={{ color: "black", textAlign: "center" }}
                 >
                   Description
                 </th>
                 <th
                   scope="col"
                   style={{ color: "black", textAlign: "center" }}
                 >
                    View Brochure
                 </th>
               
               </tr>
             </thead>
             <tbody className="text-center">
             {brochure==null?"":brochure.map(data=>{
           return(
               <tr key={data._id}>
              
                  <td>{data.title}</td>
  
                 
                   <td>
                   
                    
                   
           <button className='btn btn-primary'onClick={()=>showpdf(data.pdf)}>Brochure</button>
                
                   </td>

           
                 </tr>
  

           )
       })}
            
             </tbody>
           </table>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>


</div>
  )
}
