import React,{useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import './LoginStyle.css'
import axios from 'axios'


export default function Signup() {
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
      
      const navigate = useNavigate()
  
      const Submit = (e)=> {
        e.preventDefault();
        axios.post('http://localhost:2045/endpoint/add-signup', {email, password})
        .then(res => { 
            if(res.data==="exist"){
                alert("User already exists")
            // navigate('/break-bulk',{state:{id:email}})
          }
          else if(res.data==="notexist"){
            navigate('/login',{state:{id:email}})
        //   alert("User is not logged in")
          } 
    
      })
      .catch(e=>{
        alert("wrong")
        console.log(e);
      })
        .catch(err => console.log(err))
  
    }

  return (
    <div>
      <div className='manu'>
           
           <form className='formStyle' onSubmit={Submit}>
           <h4>SignUp </h4><br />
           <input type="email" className='form-control' placeholder='Admin Email' required onChange={(e)=>{setEmail(e.target.value)}} />
           <br/>
           <input type="password" className='form-control'placeholder='Password' required onChange={(e)=>{setPassword(e.target.value)}} />
           <br />
           <button className='btn btn-primary' type="submit">Submit</button>&nbsp;
           <Link class="btn btn-danger" to="/pdf-list" >Cancel</Link>
                                
               </form>      
               </div> 
    </div>
  )
}
