import React from 'react'
import UserNav from './UserNav'
import About1 from "../assests/About.jpg"
import Option from './Option';
// import '../Components/AboutStyle.css';


export default function About() {

  
const imgAboutBaStyle = {
  maxWidth: "920px",
  marginTop: "21px",
  paddingTop: "50px",
  marginLeft: "485px",
};

const imgAboutBadivStyle = {
  marginTop: "-17px",
}

const hrStyle = {
  border: "none",
  borderTop: "2px solid #8e0c56",
  marginTop: "20px",
};

const aboutStyle = {
  fontSize: "30px",
  fontFamily: "sans-serif",
  marginTop: "20px",
  marginLeft: "210px",
  color: "#8e0c56",
};

const paraStyle = {
  fontSize: "20px",
  fontFamily: "sans-serif",
  paddingLeft: "205px",
  paddingRight: "300px",
  paddingTop: "25px",
};

const Content = {
  marginTop: "-365px",
};


  return (
    <div>
      <UserNav />
      <Option />
      <div >
     
        <div style={Content}>
        <div style={imgAboutBadivStyle}>
          <img src={About1} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
        <div>
          <h1 style={aboutStyle}><b>History</b></h1>
          <p style={paraStyle}>
            Cooperative is a product of decade long effort by the young
            Co-Operators of South Canara.
            <br />
            <br />
            The economic empowerment through mutual Co-Operation is the motto of
            the organization. The Sahakari was inaugurated by then central
            minister Mr. Suresh Prabhu, on 2nd September, 2001 in Puttur Town,
            birth place of Shri Molahalli Shiva Rao, Beacon Light & Co-Operative
            movement. The time of birth of the organization was significant, as
            the district then was in the middle of Economic crisis, with the
            closure of many NBFCS, the prices of areca nut, the main commercial
            crop of the district, was at rock bottom.
            <br />
            <br />
            Even with such adverse economic conditions, the Sahakari could
            mobilize more than 500 members with a capital of Rs.10 lakhs.
          </p>
        </div>
      </div>
         </div>
    </div>
  )
}
