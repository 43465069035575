import React,{useEffect, useState} from 'react'
import Image1 from "../assests/SaraswathiSahakari-banner-1.jpg"
import Image2 from "../assests/SaraswathiSahakari-banner-2.jpg"
import Image3 from "../assests/SaraswathiSahakari-banner-3.jpg"
import Image4 from "../assests/SaraswathiSahakari-banner-4.jpg"
import Image5 from "../assests/SaraswathiSahakari-banner-5.jpg"
import quote from "../assests/quote.gif"
import bank from "../assests/bank.jpg"
import thank from "../assests/thank.jpg"
import UserNav from './UserNav'
import Slider from 'react-slick';
import '../Components/UserHomeStyle.css';
import axios from "axios";

export default function UserHome() {
  const [monthly_financial_updates, setmonthly_financial_updates] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:2045/endpoint/get-monthlyy")
      .then((result) => setmonthly_financial_updates(result.data))
      .catch((err) => console.log(err));
  }, []);
  // const [financial, setFinancial] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:2045/endpoint/get-financiall")
  //     .then((result) => setFinancial(result.data))
  //     .catch((err) => console.log(err));
  // }, []);

  const [showFirstText, setShowFirstText] = useState(true);
  const [showSecondText, setShowSecondText] = useState(false);
  const [showThirdText, setShowThirdText] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstText(true);
      setShowSecondText(false);
      setShowThirdText(false);
    }, 5000); // Change text after 2 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstText(false);
      setShowSecondText(true);
      setShowThirdText(false);
    }, 6000); // Change text after 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstText(false);
      setShowSecondText(false);
      setShowThirdText(true);
    }, 10000); // Change text after 10 seconds

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300, // Transition speed in milliseconds
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 2050, // Delay between slides in milliseconds
  };

  return (
    <div>
      <div  >
      <UserNav />
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img alt="Saraswathi Sahakari banner 1" src={Image1} className="full-width-image" />
          </div>
          <div>
            <img alt="Your second image description" src={Image2} className="full-width-image" />
          </div>
          <div>
            <img alt="Your second image description" src={Image3} className="full-width-image" />
          </div>
          <div>
            <img alt="Your second image description" src={Image4} className="full-width-image" />
          </div>
          <div>
            <img alt="Your second image description" src={Image5} className="full-width-image" />
          </div>
        </Slider>
      </div>
<section>
  <div >
  <h1 className='custom-style'>
                SSCSSL/HO/RECRUITMENT/001/2023-24 NOTIFICATION
              </h1>
              <br></br>
              <div className="linkp">
                <a
                  href="your-link-here"
                  style={{ textDecoration: "none" }}
                >
                  Recruitment Exam: To download the list of candidates selected
                  for the interview, click here.
                </a>
                <br />
                <br />
                <a
                  href="your-link-here"
                  style={{  textDecoration: "none" }}
                >
                  To download the list of candidates selected for the Attender
                  Post interview, click here.
                </a>
  </div>
<br></br>
  <div>
      <p className='pStyle'>
      <img alt='hhh' src={quote} className='imgStyle'/>
      Welcome to Shri Saraswathi Credit Souharda Sahakari Ltd, a
                growing Sahakari in the coastal area of Karnataka state which
                strives for the financial upliftment of the members. A product
                by the young Co-Operators, the Co-Operative started its
                functioning in 2001. The Co-Operative is registered under
                Karnataka State Souharda Sahakari Act 1997; its Head Office is
                situated in Puttur, DK. A due respect to the Souharda
                Co-Operative Principles has made the Co-Operative to focus on
                continued growth.
                <img alt='hhh' src={quote} className='imgStyle'/>
    </p>
   
  </div>
 <div>
 <h1 className='aboutStyle'><b>About Trust</b></h1>
 </div>
 <div>
 <img alt='bank' src={bank} className='imgbank'/>
 <p className='bankstyle'>
                  Shri Saraswathi Charitable Trust (R) is the subsidiary
                  institution of Shri Saraswathi Credit Souharda Sahakari Ltd
                  which is founded in accordance with section15 of Karnataka
                  Souharda Sahakari Act 1997 . The prime motto of the Trust is
                  social commitment. ie. Concern for community which is one of
                  the cooperative principles.{" "}
  </p>
    <a
    className='readbank'
                  href="subsidiary"
                  style={{ textDecoration: "none" }}
                >
                  Read More
    </a>
 </div>
  

  </div>
  
</section>
<section>
<div className='thank-text'>
                <div className='slidefont'>
                  {showFirstText && (
                    <p>
                      <strong>Sahakari Services and Offers</strong>
                      <br />
                      <br />
                      This support has given a tremendous confidence to serve
                      you better in the future days. Sahakari's Services and
                      Offers.
                      <p>
                        <br />
                        <br />
                        The Management and staff of Shri Saraswathi Credit
                        forever endeavour to serve you better.
                      </p>
                    </p>
                  )}
                  {showSecondText && (
                    <p>
                      <br />
                      <strong>
                        Financial Status As On 31-03-2024 (Unaudited)
                      </strong>
                      <br />
                      <br />
                      Members: 45077
                      <br />
                      Share Capital: 9.75 Cr
                      <br />
                      Owned Funds: 37 Cr
                      <br />
                      Working capital: 355 Cr
                      <br />
                      Deposits: 316 Cr
                      <br />
                      Loans: 287 Cr
                      <br />
                      Business: 603 Cr
                      <br /> Net Profit: 5.18 Cr
                      <br />
                      G. NPA :3.50%
                      <br />
                      N.NPA :1.45%
                      <br />
                      Branches: 19
                    </p>
                  )}
                  {showThirdText && (
                //    <table className="table table-bordered table-striped">
                //    <thead>
                //      <tr>
                //        <th scope="col" style={{ color: "black", textAlign: "center" }}>Year</th>
                //        <th scope="col" style={{ color: "black", textAlign: "center" }}>Members</th>
                //        <th scope="col" style={{ color: "black", textAlign: "center" }}>Share Capital</th>
                     
                //      </tr>
                //    </thead>
                //    <tbody className="text-center">
                //      {financial.map((item) => (
                //        <tr key={item._id}>
                //          <td>{item.year}</td>
                //          <td>{item.members}</td>
                //          <td>{item.share_capital}</td>
                    
                //        </tr>
                //      ))}
                //    </tbody>
                //  </table>
                // <div className="col-md-5">
                // <div className="card">
                //   <div className="card-body">
                  
                  <table class="table table-hover">
      <tbody>
      <strong>Financial Status</strong>
      {/* <tr>
              <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>COSMIC POLARIS (LOLO)</b></td>
              <td  style={{ color: "#fff", textAlign: "center",  backgroundColor:  "#36304a" }}><b>LIFTING CAPACITY UP TO 70 MT</b></td>
          </tr> */}
          {/* <tr style={{backgroundColor: "#314464", color: "#fff"}}>
              <td colspan="2">COSMIC POLARIS (LOLO)</td>
          </tr> */}
          <tr>
              <td class="calling-ports">Date:</td>
              <td class="est-date">
                  <table >
                      <tbody>
                          <tr>
                              {monthly_financial_updates.map((item) => (
                                  <td key={item._id}>{item.date}</td>
                              ))}
                          </tr>
                      </tbody>
                  </table>
              </td>
          </tr>
          <tr>
            <td class="calling-ports"> Deposits:</td>
            <td class="est-date">
                <table>
                    <tbody>
                        <tr>
                            {monthly_financial_updates.map((item) => (
                                <td key={item._id}>{item.deposits}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports"> Loans:</td>
            <td class="est-date">
                <table>
                    <tbody>
                        <tr>
                            {monthly_financial_updates.map((item) => (
                                <td key={item._id}>{item.loans}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports"> Share:</td>
            <td class="est-date">
                <table>
                    <tbody>
                        <tr>
                            {monthly_financial_updates.map((item) => (
                                <td key={item._id}>{item.share}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports"> Working Capital:</td>
            <td class="est-date">
                <table>
                    <tbody>
                        <tr>
                            {monthly_financial_updates.map((item) => (
                                <td key={item._id}>{item.working_capital}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td class="calling-ports"> Members:</td>
            <td class="est-date">
                <table>
                    <tbody>
                        <tr>
                            {monthly_financial_updates.map((item) => (
                                <td key={item._id}>{item.members}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
          </tbody>
          </table>
        //   </div>
        //  </div>
        //  </div>
      
      
                    // {/* <p>
                    //   <strong>Financial Status as on 31-05-2024</strong>
                    //   <br />
                    //   <br />
                    //   Deposits: 315.54Cr
                    //   <br />
                    //   Loans: 297.67Cr
                    //   <br />
                    //   W.Capital: 349.18Cr
                    //   <br />
                    //   Share: 9.67Cr
                    //   <br />
                    //   Members: 45,809
                    // </p> */}
                  )}
                  
      </div>
      <img src={thank} alt="Thank to" className='thanks'/>

</div>
</section>
<section>
<div>
                <h1 className='history'>History</h1>
                <div className='backgroundBoxStyle'>
                  {
                    <p  className='backgroundBoxparaStyle'>
                      Cooperative is a Product of decade effort by the young
                      Co-Operators of South Canara. The economic empowerment
                      through cooperation is the motto.{" "}
                    </p>
                  }
                  <a
                  className='aAboutHistStyle'
                    href="about"
                    style={{  textDecoration: "none" }}
                  >
                    Read More
                  </a>
                </div>
  </div>

  <div >
  <h1 className='servicesStyle'>Services</h1>
                <div  className='backgroundBoxServStyle'>
                  {
                      <p className='par-text'>
                      Vehicle Loans: We provide New vehicle and Old vehicle
                      Loans. Jewel Loans: If you need funds for any purpose or
                      any consignees jewel loan scheme will be ideal.{" "}
                    </p>

                  }
                  <a
                  className='aAboutServStyle'
                    href="service"
                    style={{  textDecoration: "none" }}
                  >
                    Read More
    </a>
  </div>
  </div>
</section>
</div>

    </div>
  );
}
