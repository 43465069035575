import React from 'react'
import UserNav from './UserNav'
import contact1 from "../assests/contact.jpg"
import Option from './Option';



const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };

    const imgStyle = {
        maxWidth: "450px",
        // marginTop: "-130px",
        marginLeft:"205px",
      };
export default function Contact() {
  return (
    <div>
        <UserNav />
      <Option />
      
      <div>
    
    <div style={imgAboutBadivStyle}>
      <img src={contact1} alt="About Us" style={imgAboutBaStyle} />
    </div>
    <div>
      <hr style={hrStyle} />
    </div>
    <div class="col-md-8" style={imgStyle}>
    <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
      Head Office
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
      <div class="accordion-body">
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong>
        <div>S. S. Sadana Vinayakanagara,</div>
        <div>Darbe, Puttur - 574202,</div>
        <div>Dakshina Kannada, Karnataka, India.</div>
        <div>Ph: 08251-237223.</div>
        <div>Mob: 9739986848</div>
        <div>Email: ho@saraswathisahakari.com</div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Administrative office
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
      <div class="accordion-body">
        <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
      <div>  First floor Radhika plaza,(Mangalamrutha soudha),</div>
<div>MS Road, Puttur.</div>
<div>Karnataka, India-574201.</div>
<div>Ph: 08251 - 237223, 236223.</div>
<div>Mob: 9739986848.</div>
<div>Email: adminho@saraswathisahakari.com.</div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      Puttur
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground floor Radhika plaza,</div>
<div>MS Road, Puttur.</div>

<div>Karnataka, India-574201.</div>
<div>Ph: 08251 - 236222, 238222.</div>
<div>Mob: 9739986845.</div>
<div>Email: msroad@saraswathisahakari.com,
             share@saraswathisahakari.com.
             </div>
             </div>
    </div>
    </div>

    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
      Sullia
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Sri Durga Complex,</div>
<div>Car street Sullia,</div>

<div>Dakshina Kannada, Karnataka, India - 574239..</div>
<div>Ph: 08257-234288.</div>
<div>Mob: 9739986843..</div>
<div>Email: sullia@saraswathisahakari.com,
           
             </div>
             </div>
    </div>
    </div>


    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
      BC Road
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground floor, Padma complex.</div>
<div>BC Road, Bantwal.</div>

<div>Dakshina Kannada, Karnataka, India - 574239..</div>
<div>Ph: 08257-234288.</div>
<div>Mob: 9739986843..</div>
<div>Email:  bcroad@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>

    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
      Mangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st floor, Royal chambers.</div>
<div>Near P V S Circle.</div>

<div>Kodialbail, Mangalore - 575003.</div>
<div>Ph: 0824-2422833.</div>
<div>Mob: 9739986840.</div>
<div>Email: mangalore@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>

    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
      Puttur
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> S. S. Sadana Vinayakanagara.</div>
<div>Darbe branch, Puttur.</div>

<div>Karnataka, India-574202.</div>
<div>Ph: 08251-237222.</div>
<div>Mob: 9739986842.</div>
<div>Email: darbe@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>


    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseEight">
   Sheshadripuram Bangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> No: 32, Sri Skanda, Siroor Park Road.</div>
<div>Near Hotel New Krishna Bhavan.</div>

<div>Sheshadripuram, Bangalore-560020.</div>
<div>Ph: 080-23462111.</div>
<div>Mob: 9538996847.</div>
<div>Email: bangalore@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine">
   Belthangady
      </button>
    </h2>
    <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ist Floor, viva complex.</div>
<div>Main road.</div>

<div>Belthangady - 574214.</div>
<div>Ph: 08256-232373.</div>
<div>Mob: 9739986841</div>
<div>Email: bly@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false" aria-controls="panelsStayOpen-collapseTen">
  Shirva
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ist Floor, Hotel Shri Mahadevi Bhavan,</div>
<div>Opp: Vijaya Bank, Main Road,</div>

<div>Shirva Udupi-574116.</div>
<div>Ph: 0820-2554488.</div>
<div>Mob: 9986663787</div>
<div>Email: shirva@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseEleven">
Jayanagar Bangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> No.28, Pump House Bus Stop,</div>
<div>Opp: Eastend Main Road,</div>

<div>Jayanagar 9th Block Bangalore - 560069.</div>
<div>Ph: 080-26630854.</div>
<div>Mob: 9739986849.</div>
<div>Email: jayanagar@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>


    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwelthy" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwelthy">
Bellare
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwelthy" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st Floor, Janatha Complex,</div>
<div>Opp: Main road, Bellare,</div>

<div>Sullia Taluk-574212,</div>
<div>Ph: 08257-271888.</div>
<div>Mob: 9739986860..</div>
<div>Email: bellare@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThirtin" aria-expanded="false" aria-controls="panelsStayOpen-collapseThirtin">
Kadaba
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThirtin" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> 1st Floor, Anugraha Complex,</div>
<div>Opp: Main road, Kadaba -574221,</div>

<div>Puttur Taluq, Dakshina Kannada, Karnataka, India.</div>
<div>Ph: 08251 – 260322</div>
<div>Mob: 7022288210</div>
<div>Email: kadaba@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>


    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFourteenn" aria-expanded="false" aria-controls="panelsStayOpen-collapseFourteenn">
Vittal
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFourteenn" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div> Ground Floor, Radhagopala Complex,</div>
<div>Opp: School road,</div>

<div>Bantwal Taluq, Dakshina Kannada, Karnataka, India.</div>
<div>Ph: 08255 - 238666</div>
<div>Mob: 9739986862</div>
<div>Email: vittal@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFourteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseFourteen">
Virajpet
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFourteen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>First floor Hafeeza Complex,</div>
<div>Opp: Gonikoppa road, Near Mohan Petrol Pamp,</div>

<div>Virajpet Kodagu Dist - 571218,</div>
<div>Ph:  08274 - 260222</div>
<div>Mob: 7022288786</div>
<div>Email: virajpet@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>

    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFiveteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseFiveteen">
T.Dasarahalli Bangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFiveteen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div># 23, First Floor, V G Complex, Opp. Varun Maruthi Motors,</div>
<div>Opp: Hesaraghatta Main Road, Nagasandra Post,</div>

<div>T. Dasarahalli, Bangalore-560073,</div>
<div>Ph:  080-28394009</div>
<div>Mob: 7022284009</div>
<div>Email:  dasaralli@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSixteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseSixteen">
Surathkal, Mangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSixteen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>Ground Floor City Pearl, Near Town Panchayath Market,</div>
<div>Opp: Idya Village, </div>

<div>Surathkal, Mangalore D. K. -575014,</div>
<div>Ph:  0824-2983616</div>
<div>Mob: 7022278606</div>
<div>Email:  surathkal@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeventeen" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeventeen">
Machohalli, Bangalore
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSeventeen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>First Floor, Rajapura Saraswatha Samaja (R.),</div>
<div>Opp: Kamath Layout, Forest Gate Stop, </div>

<div>Magadi Road, Machohalli,Bangalore-560091,</div>
<div>Ph: 080-29774655</div>
<div>Mob: 9620611588</div>
<div>Email:  machohalli@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEighteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseEighteen">
Gurupura KaiKamba
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEighteen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>Pompei Matha Commercial Complex, First Floor,</div>
<div>Opp: Bajpe Road Gurupura-Kaikamba,Kandavara Village, </div>

<div>Mangalore Taluk Dk - 574151</div>
<div>Ph: 0824-2951219.</div>
<div>Mob: 7022284008</div>
<div>Email:  gk@saraswathisahakari.com
           
             </div>
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNineteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseNineteen">
Madanthyar
      </button>
    </h2>
    <div id="panelsStayOpen-collapseNineteen" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>D.NO.2-58(5), A.N. Complex, First Floor,</div>
<div>Opp: Near SBI Bank, Main Road, Maladi Village, </div>

<div>Madanthyar Post. Belthangady Tq D.K- 574224</div>
{/* <div>Ph: 0824-2951219.</div>
<div>Mob: 7022284008</div>
<div>Email:  gk@saraswathisahakari.com */}
           
             {/* </div> */}
             </div>
    </div>
    </div>



    <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwenty" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwenty">
Mudipu
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwenty" class="accordion-collapse collapse ">
      <div class="accordion-body">
      <strong>Shri Saraswathi Credit Souharda Sahakari Sangha Ltd.</strong> 
     <div>"Shruti Towers" Second floor, Mudipu,</div>
<div>Opp: Kurnadu Post and Village Ullal Taluk, </div>

<div>Dakshina Kannada District.</div>
<div>Ph: 08255-260225</div>
<div>Mob: 7019002528</div>
<div>Email:  mdp@saraswathisahakari.com


           
             </div>
             </div>
    </div>
    </div>




    
    </div>
  </div>
</div>
    </div>
   
  )
}
