import React,{useState} from 'react'
import './Pdff.css'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import Navbar from './Navbar'
import { Link } from "react-router-dom";


export default function AddBroucher() {

    const [title,setTitle]=useState('');
    const [file,setFile]=useState('');

    const navigate = useNavigate()

    const submitImage=async(e)=>{
        e.preventDefault();
        const formData=new FormData();
        formData.append("title",title);
        formData.append("file",file);
        console.log(title,file)
        const result=await axios.post('http://localhost:2045/endpoint/add-brochure',
        formData,{
            headers: {"Content-Type": "multipart/form-data"},
        });
        console.log(result);
        navigate('/list-brochure')

    };
  return (
    <div>
       <Navbar ></Navbar>
    <div className='manu'>
           
<form className='formStyle' onSubmit={submitImage}>
<h4>Upload Pdf </h4><br />
<input type="text" className='form-control' placeholder='title' required onChange={(e)=>setTitle(e.target.value)} />
<br/>
<input type="file" className='form-control' accept="application/pdf" required onChange={(e)=>setFile(e.target.files[0])}/>
<br />
<button className='btn btn-primary' type="submit">Submit</button>&nbsp;
<Link class="btn btn-danger" to="/pdf-list" >Cancel</Link>
                     
    </form>      
    </div>

    </div>
  )
}
