import React,{useEffect, useState} from 'react'
import axios from 'axios'
import Navbar from './Navbar'

export default function Pdflist() {
    const [allImage, setAllImage] = useState(null);
useEffect(() =>{
    getpdf();
}, []);

    const getpdf = async()=>{
        const result = await axios.get("http://localhost:2045/endpoint/pdf");
        console.log(result.data.data);
        setAllImage(result.data.data);
    };

    const showpdf=(pdf)=>{
        window.open(`http://localhost:2045/files/${pdf}`, "_blank", "noreferrer");
    }

    const handelDelete = (id) => {
        // Prompt the user with a confirmation dialog
        const confirmDelete = window.confirm("Are you sure you want to delete?");
      
        // If the user confirms, proceed with the delete operation
        if (confirmDelete) {
          axios
            .delete("http://localhost:2045/endpoint/soft-pdf/" + id)
            .then((res) => {
              console.log(res);
              window.location.reload();
            })
            .catch((errr) => console.log(errr));
        }
      };
      
  return (
    <div className='uploaded'>
         <Navbar ></Navbar>
        {/* <h4>pdf</h4> */}
        {/* <div className='output-div'>
            {allImage==null?"":allImage.map(data=>{
                return(
<div className='inner-div'>
                <h6>title: {data.title}</h6>
                <button className='btn btn-primary'onClick={()=>showpdf(data.pdf)}>Show Pdf</button>
            </div>
                )
            })}
            
        </div> */}

<div>
            <br></br>
        <h2 className="head" style={{ color: "black", textAlign: "center" }}>
     Uploaded Document 
        </h2>
        <br></br>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-15">
              <div className="card">
                <div className="card-body">
                  <a href="pdf" className="btn btn-secondary float-end">
                    Upload PDF
                  </a>
                  <br />
                  <br />
                 
                  <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        style={{ color: "black", textAlign: "center" }}
                      >
                         View Document
                      </th>
                     <th  
                     scope="col"
                        style={{ color: "black", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                  {allImage==null?"":allImage.map(data=>{
                return(
                    <tr key={data._id}>
                   
                       <td>{data.title}</td>
       
                      
                        <td>
                        
                         
                        
                <button className='btn btn-primary'onClick={()=>showpdf(data.pdf)}>View Pdf</button>
                     
                        </td>

                        <td>
                        <button
                            className="btn btn-danger"
                            onClick={(e) => handelDelete(data._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
       

                )
            })}
                 
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
