import React,{useState} from 'react'
import imgoption from "../assests/OptionBox.png"
import { Link } from "react-router-dom";

const imgStyle = {
  maxWidth: "280px",
  marginTop: "-130px",
  marginLeft:"205px",
};
export default function Option() {

  const [hoveredServ, setHoveredServ] = useState(false);
  const [hoveredAnnual, setHoveredAnnual] = useState(false);
  const [hoveredInterest, setHoveredInterest] = useState(false);
  const [hoveredLoans, setHoveredLoans] = useState(false);
  const [hoveredAwards, setHoveredAwards] = useState(false);
  
const aPresidentStyle = {
  position: "relative",
  top:"40px",
};

const aAboutServStyle = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  textAlign: "justify",
  marginLeft: "88px",
  top: "120px",
  zIndex: "1000",
  paddingLeft: "190px",
  color: hoveredServ ? "#1e6497" : "#582e4b",
};


const aAnnualReStyle = {
  position: "relative",
  top:"60px",
};

const aAnnualStyle = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  textAlign: "justify",
  marginLeft: "98px",
  top: "200px",
  left: "10.5%",
  transform: "translateX(-50%)",
  paddingLeft: "190px",
  color: hoveredAnnual ? "#1e6497" : "#582e4b",
};

const aRateStyle = {
  position: "relative",
  top: "85px",
};

const aInterestStyle = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  textAlign: "justify",
  marginLeft: "105px",
  top: "268px",
  left: "10.5%",
  transform: "translateX(-50%)",
  paddingLeft: "190px",
  color: hoveredInterest ? "#1e6497" : "#582e4b",
};

const aLoansDivStyle = {
  position: "relative",
  top:"108px",
};

const aLoansStyle = {
  fontSize: "18px",
  fontFamily: "sans-serif",
  textAlign: "justify",
  marginLeft: "125px",
  top: "318px",
  left: "10.5%",
  transform: "translateX(-50%)",
  paddingLeft: "190px",
  color: hoveredLoans ? "#1e6497" : "#582e4b",
};

const aAwardsDivStyle = {
  position: "relative",
  top:"130px",
};

const aAwardsStyle = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  textAlign: "justify",
  marginLeft: "120px",
  top: "365px",
  left: "10.5%",
  transform: "translateX(-50%)",
  paddingLeft: "190px",
  color: hoveredAwards ? "#1e6497" : "#582e4b",
};


  
  return (
    <div>

<div style={aPresidentStyle}>
          <a
            href="president"
            style={{ ...aAboutServStyle, textDecoration: "none" }}
            onMouseEnter={() => setHoveredServ(true)}
            onMouseLeave={() => setHoveredServ(false)}
          >
            <strong>President's Message</strong>
          </a>
        </div>
        <div style={aAnnualReStyle}>
          <Link
            to="/notify"
            style={{ ...aAnnualStyle, textDecoration: "none" }}
            onMouseEnter={() => setHoveredAnnual(true)}
            onMouseLeave={() => setHoveredAnnual(false)}
          >
            <strong>Annual Report</strong>
          </Link>
        </div>
        <div style={aRateStyle}>
          <a
            href="interest"
            style={{ ...aInterestStyle, textDecoration: "none" }}
            onMouseEnter={() => setHoveredInterest(true)}
            onMouseLeave={() => setHoveredInterest(false)}
          >
            <strong>Interest Rate</strong>
          </a>
        </div>
        <div style={aLoansDivStyle}>
          <a
            href="loans"
            style={{ ...aLoansStyle, textDecoration: "none" }}
            onMouseEnter={() => setHoveredLoans(true)}
            onMouseLeave={() => setHoveredLoans(false)}
          >
            <strong>Loans</strong>
          </a>
        </div>
        <div style={aAwardsDivStyle}>
          <a
            href="award"
            style={{ ...aAwardsStyle, textDecoration: "none" }}
            onMouseEnter={() => setHoveredAwards(true)}
            onMouseLeave={() => setHoveredAwards(false)}
          >
            <strong>Awards</strong>
          </a>
        </div>

       <div>
 <img src={imgoption} alt="OptionBox" style={imgStyle} />
    
      </div>
    </div>
  )
}
