import React from 'react'
import UserNav from './UserNav'
import Orgnization1 from "../assests/management.png"
import devakik from "../Management/DevakiK.png"
import deviprasad from "../Management/Deviprasad.png"
import harishborkar from "../Management/HarishBorkar.png"
import hemanth from "../Management/HemanthKumarKR.png"
import jayaram from "../Management/JayaramP.png"
import prakash from "../Management/Prakashchandra.png"
import raja from "../Management/RajagopalaB.png"
import ramesh from "../Management/RameshchandraM.png"
import saraswathi from "../Management/SaraswathiN.png"
import sathis from "../Management/Sathischandra.png"
import umesh from "../Management/UmeshPrabhuK.png"
import vasanth from "../Management/Vasanth.jpg"
import Option from './Option';




const aboutStyle = {
    fontSize: "30px",
    fontFamily: "sans-serif",
    marginTop: "20px",
    marginLeft: "210px",
    color: "#8e0c56",
  };
  
  const paraStyle = {
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "300px",
    paddingRight: "300px",
    paddingTop: "25px",
  };
  
  const paraStyle2 = {
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "770px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-125px",
  };
  
  const paraStyle3 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "120px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "20px",
  };
  
  const paraStyle4 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "567px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-170px",
  };
  
  const paraStyle5 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "965px",
    paddingRight: "150px",
    paddingTop: "1px",
    marginTop: "-165px",
    marginLeft: "60px",
  };
  
  const paraStyle6 = {
    fontSize: "18px",
    fontFamily: "sans-serif",
    paddingLeft: "92px",
    paddingRight: "190px",
    paddingTop: "1px",
    marginTop: "25px",
    marginLeft: "30px",
  };
  
  const paraStyle7 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "590px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-165px",
  };
  
  const paraStyle8 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "970px",
    paddingRight: "150px",
    paddingTop: "1px",
    marginTop: "-215px",
    marginLeft: "60px",
  };
  
  const paraStyle9 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "310px",
    paddingRight: "300px",
    paddingTop: "25px",
  };
  
  const paraStyle10 = {
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "770px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-160px",
  };
  
  const paraStyle11 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "310px",
    paddingRight: "300px",
    paddingTop: "25px",
  };
  
  const paraStyle12 = {
    fontSize: "20px",
    fontFamily: "sans-serif",
    paddingLeft: "790px",
    paddingRight: "300px",
    paddingTop: "1px",
    marginTop: "-160px",
  };
  
  const paraStyle13 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "250px",
    paddingRight: "300px",
    paddingTop: "110px",
  };
  
  const paraStyle14 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "800px",
    paddingRight: "300px",
    paddingTop: "0px",
    marginTop: "-245px",
  };
  
  const paraStyle15 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "250px",
    paddingRight: "300px",
    paddingTop: "70px",
  };
  
  const paraStyle16 = {
    fontSize: "19px",
    fontFamily: "sans-serif",
    paddingLeft: "800px",
    paddingRight: "300px",
    paddingTop: "0px",
    marginTop: "-240px",
  };
  
  const imgSathischandra = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "25px",
    marginLeft: "300px",
  };
  
  const imgUmesh = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "25px",
    marginLeft: "110px",
  };
  
  const imgDeviprasad = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "50px",
    marginLeft: "115px",
  };
  
  const imgJay = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "100px",
  };
  
  const imgHarish = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "100px",
  };
  
  const imgPrakash = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "50px",
    marginLeft: "115px",
  };
  
  const imgHemanth = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "100px",
  };
  
  const imgRamesh = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "30px",
    marginLeft: "100px",
  };
  
  const imgRaj = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "70px",
    marginLeft: "300px",
  };
  
  const imgSaraswathi = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "25px",
    marginLeft: "110px",
  };
  
  const imgDevaki = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "50px",
    marginLeft: "300px",
  };
  
  const imgVasanth = {
    width: "23%", // Ensure the image takes up the full width available
    height: "auto",
    marginTop: "25px",
    marginLeft: "110px",
  };
  
  const hrStyleRav1 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "-130px",
    marginLeft: "0px",
  };
  
  const hrStyleRav2 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStyleRav3 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStyleRav4 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStyleRav5 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStyleRavDiv = {
    marginTop: "-175px",
  };
  
  const hrStylegray1 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "-130px",
    marginLeft: "0px",
  };
  
  const hrStylegray2 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStylegray3 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStylegray4 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStylegray5 = {
    border: "none",
    width: "380px",
    borderTop: "1px solid #9e9e9e",
    marginTop: "44px",
    marginLeft: "0px",
  };
  
  const hrStylegrayDiv = {
    marginTop: "-175px",
  };
  
  const hrStylegray2Div = {
    marginTop: "-120px",
  };
  
  const hrStyleVasDiv = {
    marginTop: "-130px",
  };
  
  const hrStyleDevDiv = {
    marginTop: "-170px",
  };
  
  const hrStyleSaraDiv = {
    marginTop: "-170px",
  };
  
  const hrStyleRajDiv = {
    marginTop: "-170px",
  };
  
  const hrStylePrakasDiv = {
    marginTop: "10px",
  };
  
  const hrStyleHemanDiv = {
    marginTop: "-170px",
  };
  
  const hrStyleRameshDiv = {
    marginTop: "5px",
  };
  
  const hrStyleDeviDiv = {
    marginTop: "-130px",
  };
  
  const hrStyleUmeshDiv = {
    marginTop: "-130px",
  };
  

const imgAboutBaStyle = {
    maxWidth: "920px",
    marginTop: "-359px",
    paddingTop: "50px",
    height: "319px",
    marginLeft: "485px",
  };
  
  const imgAboutBadivStyle = {
    marginTop: "-17px",
  }
  
  const hrStyle = {
      border: "none",
      borderTop: "2px solid #8e0c56",
      marginTop: "10px",
    };
  

export default function Orgnization() {
    


   

  return (
    <div>
      <UserNav />
      <Option />
      <div>
    
        <div style={imgAboutBadivStyle}>
          <img src={Orgnization1} alt="About Us" style={imgAboutBaStyle} />
        </div>
        <div>
          <hr style={hrStyle} />
        </div>
        <div>
        <h1 style={aboutStyle}><b>Management Team</b></h1>
        </div>
        <div>
          <img src={sathis} alt="SRS" style={{ ...imgSathischandra }} />
          <img src={umesh} alt="UME" style={{ ...imgUmesh }} />
          <p style={paraStyle}>
            Devisadana, Punacha, Bantwal.
            <br />
            <br />
            Ph: 08255-268229, 08255-276319
            <br />
            <br />
            Mob: +91-9448696108
            <div style={hrStyleDeviDiv}>
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
          <p style={paraStyle2}>
            S/o Appayya Prabhu,
            <br />
            <br />
            Kuntihithlu House, Doddathota Post,
            <br />
            <br />
            Sullia Tq D.K-574248
            <br />
            <br />
            Mob: +91-9480016905
            <div style={hrStyleUmeshDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
        </div>


        <div>
          <img src={ deviprasad} alt="SRS" style={{ ...imgDeviprasad }} />
          <img src={jayaram} alt="SRS" style={{ ...imgJay }} />
          <img src={ harishborkar} alt="SRS" style={{ ...imgHarish }} />
          <p style={paraStyle3}>
            S/o Srinivasa sharma K.
            <br />
            <br />
            Sharma Farms, Kallaje House,
            <br />
            <br />
            Post, Village, Bantwal Taluk D. K.
            <br />
            <br />
            Mob: +91-9886652495
            <div style={hrStylePrakasDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
          <p style={paraStyle4}>
            S/o Gopalkrishna, 3rd cross velencia,
            <br />
            <br />
            Veramma Compound, Sootarpete
            <br />
            <br />
            Mangalore.
            <br />
            <br />
            Mob: +91-9845100852
            <div style={hrStyleHemanDiv}>
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
              <hr style={hrStylegray5} />
            </div>
          </p>
          <p style={paraStyle5}>
            S/o Gopala Borkar,
            <br />
            <br />
            #1/26, Kattalakana House, Nidpalli Post,
            <br />
            <br />
            Village, Puttur Taluk D. K. - 574259
            <br />
            <br />
            Mob: +91 9449452099
            <div style={hrStyleRameshDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
        </div>

        <div>
          <img src={ prakash} alt="SRS" style={{ ...imgPrakash }} />
          <img src={hemanth} alt="SRS" style={{ ...imgHemanth }} />
          <img src={ramesh} alt="SRS" style={{ ...imgRamesh }} />
          <p style={paraStyle6}>
            S/o Ananthapadmanabha Nayak,
            <br />
            <br />
            Panjigaru House, Kalanja Post and Village,
            <br />
            <br />
            Sullia Tq, D.K-574212
            <br />
            <br />
            Mob: +91-9632275452
            <div style={hrStylePrakasDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
          <p style={paraStyle7}>
            S/o Ramakrishna Nayak,
            <br />
            <br />
            Kandadka House, Duggaladka Post,
            <br />
            <br />
            Sullia Kasaba Village, Sullia Tq,
            <br />
            <br />
            D.K-574239
            <br />
            <br />
            Mob: +91-9449569177
            <div style={hrStyleHemanDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
              <hr style={hrStylegray5} />
            </div>
          </p>
          <p style={paraStyle8}>
            S/o Ananda Nayak,
            <br />
            <br />
            Mundakochi House,
            <br />
            <br />
            Ariyadka Post and Village, Puttur Tq. D.K
            <br />
            <br />
            Mob: +91-9480056274
            <div style={hrStyleRameshDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
        </div>

        <div>
          <img src={raja} alt="SRS" style={{ ...imgRaj }} />
          <img src={saraswathi} alt="UME" style={{ ...imgSaraswathi }} />
          <p style={paraStyle9}>
            S/o Mariyappa Nayak,
            <br />
            <br />
            D.No. 3/212, Naduvadka House,
            <br />
            <br />
            Irde Post and Village, Puttur Tq, D.K
            <br />
            <br />
            Mob: +91-9449101046
            <div style={hrStyleRajDiv}>
              <hr style={hrStyleRav2} />
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
          <p style={paraStyle10}>
            W/o Harishchandra Nayak,
            <br />
            <br />
            Natekallu House, Vitla Mudnoor Village,
            <br />
            <br />
            Kambalabettu Post, Bantwal Tq, D.K
            <br />
            <br />
            Mob: +91-9448596771
            <div style={hrStyleSaraDiv}>
              <hr style={hrStyleRav2} />
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
        </div>

   
        <div>
          <img src={devakik} alt="SRS" style={{ ...imgDevaki }} />
          <img src={vasanth} alt="UME" style={{ ...imgVasanth }} />
          <p style={paraStyle11}>
            W/o Harish Nayak,
            <br />
            <br />
            Santyar Balakka House, Aryapu Village,
            <br />
            <br />
            Kaikara Post, Puttur Tq, D.K.
            <br />
            <br />
            Mob: +91-8277300203
            <div style={hrStyleDevDiv}>
              <hr style={hrStyleRav2} />
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
          <p style={paraStyle12}>
            Darbe, Puttur-574202. Dakshina
            <br />
            <br />
            Kannada, Karnataka, India.
            <br />
            <br />
            Mob: +91-9449773579
            <div style={hrStyleVasDiv}>
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
        </div>
        <div>
          <p style={paraStyle13}>
            <strong>Mr. Vedavyasa K (Professional director)</strong>
            <br />
            <br />
            S/o Divakara Bhat K,
            <br />
            <br />
            "Sthuthi", #6/1, Kamath Layout, Forest Gate,
            <br />
            <br />
            Machohalli Magadi Main Road,
            <br />
            <br />
            Bapa Village and Post, Bangalore – 560091
            <br />
            <br />
            Mob: +91-9980129663
            <div style={hrStyleRavDiv}>
              <hr style={hrStyleRav1} />
              <hr style={hrStyleRav2} />
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
          <p style={paraStyle14}>
            <strong>Mr. B Vasanth Shankar (Professional director)</strong>
            <br />
            <br />
            S/o B Shankara Narayana,
            <br />
            <br />
            #2-3-5-105C/D-0-1, Thushara House,
            <br />
            <br />
            Neharu Nagara Post,
            <br />
            <br />
            Bairikatte Karmala, Puttur Tq D.K- 574203
            <br />
            <br />
            Mob: +91-9379622311, +91-9449801105
            <div style={hrStylegrayDiv}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
              <hr style={hrStylegray5} />
            </div>
          </p>
        </div>
        <div>
          <p style={paraStyle15}>
            <strong>Mr. Raveesha P, (Functional director)</strong>
            <br />
            <br />
            S/o Jayantha Nayak,
            <br />
            <br />
            Posavalike House,
            <br />
            <br />
            Punacha Post and Village, Bantwal TQ,
            <br />
            <br />
            Dakshina Kannada - 574281
            <br />
            <br />
            Mob: +91-9740926212
            <div style={hrStyleRavDiv}>
              <hr style={hrStyleRav1} />
              <hr style={hrStyleRav2} />
              <hr style={hrStyleRav3} />
              <hr style={hrStyleRav4} />
              <hr style={hrStyleRav5} />
            </div>
          </p>
          <p style={paraStyle16}>
            <strong>Mr. Shrikantha Rao V D (Functional director)</strong>
            <br />
            <br />
            S/o V A Dayananda Rao,
            <br />
            <br />
            Ramanagara House, Betoli Post and Village,
            <br />
            <br />
            Virajpet Tq, Kodagu-571218,
            <br />
            <br />
            Mob: +91-9686805673
            <div style={hrStylegray2Div}>
              <hr style={hrStylegray1} />
              <hr style={hrStylegray2} />
              <hr style={hrStylegray3} />
              <hr style={hrStylegray4} />
            </div>
          </p>
        </div>
        </div>
    </div>
  )
}
